import { Title } from '@solidjs/meta';
import { Tab, TabList, TabPanel, Tabs } from '@troon/ui';

export default function TabsPage() {
	return (
		<>
			<Title>Tabs | Interactive | Style Guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Tabs</h1>
			<Tabs>
				<TabList label="Demo tabs">
					<Tab value="tacos">Tacos</Tab>
					<Tab value="burritos">Burritos</Tab>
					<Tab value="taquitos">Taquitos</Tab>
				</TabList>

				<TabPanel value="tacos">This is tacos</TabPanel>
				<TabPanel value="burritos">Burritos burritos burritos</TabPanel>
				<TabPanel value="taquitos">Did you get my taquitos yet?</TabPanel>
			</Tabs>
		</>
	);
}
